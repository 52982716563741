import React, { FC } from 'react';
import { graphql } from 'gatsby';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Layout from 'layout/Layout';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import RecipeListDesktop from 'components/RecipeListDesktop';
import RecipeListMobile from 'components/RecipeListMobile';
import { TagEventTracker } from 'components/TagEventTracker';

import { nl2br } from '../../utils/functions';
import { RecipeListProps } from './models';

import 'styles/main.scss';
import './RecipeList.scss';

const RecipeList: FC<{ data: RecipeListProps }> = ({
  data: {
    recipeList: { urls, seo, title, description, imageDesktop, imageMobile, headband },
    allRecipe: { nodes: items },
  },
}) => {
  const { title: seoTitle, keywords: seoKeywords, description: seoDescription } = seo;

  return (
    <Layout headerTransparent {...{ headband }}>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: seoDescription,
          metaKeywords: seoKeywords,
        }}
      />
      <TagEventTracker
        eventType="view_item_list"
        data={{
          value: seoTitle,
          ecommerce: {
            items: items.map((item) => ({
              itemName: item.title,
            })),
          },
        }}
      >
        <div className="recipe-list">
          <div className="recipe-list__banner">
            <Image
              imageData={imageDesktop}
              alt={imageDesktop.altText}
              className="d-none d-md-block"
            />
            <Image
              imageData={imageMobile}
              alt={imageMobile.altText}
              className="d-block d-md-none"
            />
            <div className="recipe-list__banner-text">
              <div className="container-fluid">
                <div className="row">
                  <div className="col text-center">
                    <h2 className="mb-0 mb-4">{title}</h2>
                    <DangerouslySetInnerHtml html={nl2br(description)} element="p" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-5 recipe-list__listing-desktop d-none d-md-block">
            <RecipeListDesktop items={items} />
          </div>
          <div className="px-5 recipe-list__listing-mobile d-block d-md-none">
            <RecipeListMobile items={items} />
          </div>
        </div>
      </TagEventTracker>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String) {
    recipeList(url: { eq: $url }) {
      urls {
        lang
        href
      }
      headband {
        cta {
          url
          name
        }
        imageDesktop {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
        imageMobile {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      seo {
        ...SEOStructureFragment
      }
      title
      description
      url
      imageDesktop {
        altText
        fallbackUrl
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920)
          }
        }
      }
      imageMobile {
        altText
        fallbackUrl
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
      }
    }
    allRecipe {
      nodes {
        title
        url
        thumbnail {
          altText
          fallbackUrl
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 700)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 700)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 700)
            }
          }
        }
      }
    }
  }
`;

export default RecipeList;
